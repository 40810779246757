import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  Divider,
  SwipeableDrawer,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import dayjs from "dayjs";

import { connect } from "react-redux";
import {
  getAllPatient,
  getAppointment,
  updateApiStatus
} from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import FormButton from "../../components/FormButton";
import AddEditPatientAppointment from "../../components/AddEditPatientAppointment";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import { MOBILE_VIEW } from "../../components/Sidebar";

import styles from "./Appointments.module.css";
import "../../assets/css/fullcalendar.css";

import PlusIcon from '@mui/icons-material/AddOutlined';
import MenuIcon from '@mui/icons-material/MenuOutlined';

import { THEME_COLOR3, APPOINTMENT_TIME_DIFF_VAL, APPOINTMENT_TIME_DIFF_TYPE } from "../../constants";

let status_filter = [
  { label: "View All", value: "all", color: "#5a8dee", checked: true },
  { label: "Active", value: "active", color: "#39da8a", checked: true },
  { label: "Completed", value: "completed", color: "#5a8dee", checked: true },
  { label: "Cancelled", value: "cancelled", color: "#ff5b5c", checked: true },
];

const Appointments = (props) => {
  const [statusFilterList, setStatusFilterList] = useState(
    JSON.parse(JSON.stringify(status_filter))
  );
  const [appointmentModalVisible, setAppointmentModalVisible] = useState(false);
  const [appointmentModalData, setAppointmentModalData] = useState(null);
  const [appointmentModalType, setAppointmentModalType] = useState("add");
  const [leftViewModalVisible, setLeftViewModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const calenderRef = useRef();

  useEffect(() => {
    getAppointment(startDate, endDate);
  }, [statusFilterList]);

  useEffect(() => {
    props.getAllPatient();
  }, []);

  useEffect(() => {
    if (calenderRef.current) {
      let doc = document.getElementsByClassName("fc-button-active");
      if (doc?.length) {
        setTimeout(() => {
          if (doc?.length) {
            doc[0].click();
          }
        }, 300);
      }
    }
  }, [props.drawerOpen]);

  const getAppointment = (start_date, end_date) => {
    let status = [];
    statusFilterList
      .filter((x) => x.checked == true && x.value != "all")
      .map((x) => {
        status.push(x.value);
      });
    let data = {
      startDate: start_date,
      endDate: end_date,
      status: JSON.stringify(status),
    };
    setStartDate(start_date);
    setEndDate(end_date);
    props.getAppointment(JSON.stringify(data));
  };

  const onEventClick = (eventInfo) => {
    let duration_amount = APPOINTMENT_TIME_DIFF_VAL;
    let duration_unit = APPOINTMENT_TIME_DIFF_TYPE;
    setAppointmentModalType("edit");
    let { extendedProps } = eventInfo.event;
    let data = {
      id: eventInfo.event.id,
      patient_uid: extendedProps?.patient_uid ? extendedProps?.patient_uid : "",
      date: dayjs(eventInfo.event.startStr),
      time: dayjs(eventInfo.event.startStr),
      endTime: eventInfo.event.endStr ? dayjs(eventInfo.event.endStr) : "",
      appt_title: extendedProps?.appt_title ? extendedProps?.appt_title : "",
      note: extendedProps?.note ? extendedProps?.note : "",
      provisional_diagnosis: extendedProps?.provisional_diagnosis ? extendedProps?.provisional_diagnosis : "",
      consultation_fee: extendedProps?.consultation_fee ? extendedProps?.consultation_fee : 0,
      status: extendedProps?.status ? extendedProps?.status : "",
      reason: extendedProps?.reason ? extendedProps?.reason : "",
      invoiceId: extendedProps?.invoice_id ? extendedProps?.invoice_id : "",
      invoiceUid: extendedProps?.invoice_uid ? extendedProps?.invoice_uid : "",
      durationAmount: duration_amount,
      durationUnit: duration_unit,
    };

    setAppointmentModalData(data);
    setAppointmentModalVisible(true);
  };

  const onDateSelect = (selectInfo) => {
    // if (selectInfo.dateStr >= moment().format("YYYY-MM-DD")) {
    let duration_amount = APPOINTMENT_TIME_DIFF_VAL;
    let duration_unit = APPOINTMENT_TIME_DIFF_TYPE;
    setAppointmentModalType("add");
    let data = {
      date: dayjs(selectInfo.date),
      durationAmount: duration_amount,
      durationUnit: duration_unit,
    };

    if (selectInfo.view.type == 'timeGridWeek' || selectInfo.view.type == 'timeGridDay') {
      let end_time_dat = dayjs(selectInfo.date).add(moment.duration("00:45:00"));
      if (dayjs(selectInfo.date).hour() == 19 && dayjs(selectInfo.date).minute() == 30) {
        end_time_dat = dayjs(selectInfo.date).add(moment.duration("00:30:00"))
        duration_amount = 30;
        duration_unit = "m"
      }

      data = {
        ...data,
        time: dayjs(selectInfo.date),
        endTime: end_time_dat,
        durationAmount: duration_amount,
        durationUnit: duration_unit,
      }
    }
    setAppointmentModalData(data);
    setAppointmentModalVisible(true);
    // }
  };

  const onPickerDateChange = (e) => {
    calenderRef.current.calendar.gotoDate(new Date(e.$y, e.$M, e.$D));
    updateAppointmentList();
  };

  const onCloseAppointmentModal = () => {
    setAppointmentModalVisible(false);
    setAppointmentModalType("add");
    setAppointmentModalData(null);
  };

  const updateAppointmentList = () => {
    let start = dayjs(
      calenderRef.current.calendar.currentData.dateProfile.activeRange.start
    ).format("YYYY-MM-DD");
    let end = dayjs(
      calenderRef.current.calendar.currentData.dateProfile.activeRange.end
    ).format("YYYY-MM-DD");
    getAppointment(start, end);
  };

  const onStatusFilter = (status, value) => {
    if (typeof status != "undefined") {
      let list = [...statusFilterList];
      if (status == "all") {
        list = list.map((x) => {
          return { ...x, checked: value };
        });
        setStatusFilterList(list);
      } else {
        let list = [...statusFilterList];
        let index = list.findIndex((x) => x.value == status);
        if (index >= 0) {
          list[index] = { ...list[index], checked: value };
        }
        if (
          list.filter((x) => x.checked == true && x.value != "all").length ==
          statusFilterList.length - 1
        ) {
          list = list.map((x) => {
            return { ...x, checked: true };
          });
        } else {
          let index1 = list.findIndex((x) => x.value == "all");
          if (index1 >= 0) {
            list[index1] = { ...list[index1], checked: false };
          }
        }
        setStatusFilterList(list);
      }
    }
  };

  const addAppointmentBtnClick = () => {
    let duration_amount = APPOINTMENT_TIME_DIFF_VAL;
    let duration_unit = APPOINTMENT_TIME_DIFF_TYPE;
    let data = {
      durationAmount: duration_amount,
      durationUnit: duration_unit,
    };

    setAppointmentModalVisible(true);
    setAppointmentModalType("add");
    setAppointmentModalData(data);
  }

  if (props.appointmentLoading && props.allPatientListLoading) {
    return (
      <Container p={MOBILE_VIEW ? 2 : 4} page={"appointments"}>
        <Loader />
      </Container>
    );
  }

  const LeftView = (
    <Box>
      <Box px={3} pt={3}>
        <FormButton
          title={"Add Appointment"}
          startIcon={<PlusIcon height={17} width={17} />}
          btnStyleView={style.btnStyleView}
          onSubmit={addAppointmentBtnClick}
        />
      </Box>
      {/*<Divider />*/}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          sx={{
            "&.MuiDateCalendar-root": { width: 270, transform: "scale(0.85, 0.85)" }
          }}
          views={["year", "day"]}
          onChange={onPickerDateChange}
          onMonthChange={onPickerDateChange}
        />
      </LocalizationProvider>
      {/*<Divider />*/}
      <Box px={3} pb={3}>
        <Typography mb={2} className={styles.filterTitle}>
          FILTER
        </Typography>
        {statusFilterList.map((filter, index) => {
          return (
            <Box key={index} mt={"-10px"}>
              <FormControlLabel
                sx={{
                  "& .MuiTypography-root": {
                    color: "#69809a !important",
                    fontFamily: "IBMPlexSansRegular !important",
                    fontSize: "0.9375rem !important",
                  },
                }}
                value={filter.value}
                onChange={(e) =>
                  onStatusFilter(e.target.value, e.target.checked)
                }
                checked={filter.checked}
                control={
                  <Checkbox
                    defaultChecked={true}
                    sx={{
                      color: "#d4d8dd",
                      "&.Mui-checked": {
                        color: filter.color,
                      },
                    }}
                  />
                }
                label={filter.label}
                labelPlacement="end"
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <Container p={MOBILE_VIEW ? 2 : 0} page={"appointments"}>
      <Card id={"appointmentDiv"} className={styles.mainView}>
        {MOBILE_VIEW ? null : LeftView}

        <SwipeableDrawer
          open={leftViewModalVisible}
          onClose={() => setLeftViewModalVisible(false)}
          onOpen={() => setLeftViewModalVisible(true)}
        >
          {LeftView}
        </SwipeableDrawer>
        <Box
          pt={3}
          sx={{ color: THEME_COLOR3 }}
          className={styles.calenderMainView}
        >
          <FullCalendar
            ref={calenderRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            noEventsText={"No Appointments"}
            allDaySlot={false}
            slotMinTime={"08:00:00"}
            slotMaxTime={"20:00:00"}
            customButtons={{
              prev: {
                text: "Prev",
                click: () => {
                  calenderRef.current.calendar.prev();
                  updateAppointmentList();
                },
              },
              next: {
                text: "Next",
                click: () => {
                  calenderRef.current.calendar.next();
                  updateAppointmentList();
                },
              },
              dayGridMonth: {
                text: "Month",
                click: () => {
                  calenderRef.current.calendar.changeView("dayGridMonth");
                  updateAppointmentList();
                },
              },
              timeGridWeek: {
                text: "Week",
                click: () => {
                  calenderRef.current.calendar.changeView("timeGridWeek");
                  updateAppointmentList();
                },
              },
              timeGridDay: {
                text: "Day",
                click: () => {
                  calenderRef.current.calendar.changeView("timeGridDay");
                  updateAppointmentList();
                },
              },
              listMonth: {
                text: "List",
                click: () => {
                  calenderRef.current.calendar.changeView("listMonth");
                  updateAppointmentList();
                },
              },
            }}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth", // user can switch between the two
            }}
            views={{
              month: {
                eventDidMount: function (info) {
                  if (calenderRef.current?.calendar.view.type == "dayGridMonth") {
                    let color = info?.event?.classNames?.[0] ? 'largeDot-' + info.event.classNames[0] : 'largeDot';
                    info.el.insertAdjacentHTML("afterBegin", "<i class=" + color + "></i>");
                  }
                },
                eventContent: function (arg) {
                  if (calenderRef.current?.calendar.view.type == "listMonth") {
                    return `${arg.event.title} - ${arg.event.extendedProps.appt_title}`;
                  }
                  return arg.event.title;
                },
              },
              week: {
                dayHeaderContent: (args) => {
                  return (
                    <Box>
                      <Typography className={styles.weekHeaderWeekText}>{moment(args.date).format('ddd')}</Typography>
                      <Typography className={styles.dayHeaderWeekText}>{moment(args.date).format('D')}</Typography>
                    </Box>
                  )
                }
              },
              day: {
                dayHeaderContent: (args) => {
                  return (
                    <Box>
                      <Typography className={styles.weekHeaderDayText}>{moment(args.date).format('ddd')}</Typography>
                      <Box  className={styles.dayHeaderDayView}>
                        <Typography className={styles.dayHeaderDayText}>{moment(args.date).format('D')}</Typography>
                      </Box>
                    </Box>
                  )
                }
              }
            }}
            initialView="timeGridWeek"
            events={props.appointmentList.filter((y) =>
              statusFilterList
                .filter((x) => x.checked == true && x.value != "all")
                .map((x) => x.value)
                .includes(y.status)
            )}
            editable={false}
            selectable={true}
            weekends={true}
            handleWindowResize={true}
            dayMaxEvents={true}
            eventClick={onEventClick}
            dateClick={onDateSelect}
          />
          {MOBILE_VIEW ? (
            <Box
              className={styles.menuIcon}
              onClick={() => setLeftViewModalVisible(true)}
            >
              <MenuIcon width={25} height={25} />
            </Box>
          ) : null}
        </Box>
      </Card>

      <AddEditPatientAppointment
        showPatient={true}
        type={appointmentModalType}
        data={appointmentModalData}
        modalVisible={appointmentModalVisible}
        onClose={onCloseAppointmentModal}
      />
      <AlertMsg />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointment: (data) => dispatch(getAppointment(data)),
    getAllPatient: (data) => dispatch(getAllPatient(data)),
    updateApiStatus: (data) => dispatch(updateApiStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
    appointmentLoading: state.userData.appointmentLoading,
    appointmentList: state.userData.appointmentList,
    allPatientListLoading: state.userData.allPatientListLoading,
    apiStatus: state.userData.apiStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);

const style = {
  btnStyleView: {
    height: 40,
    width: "100%",
  },
};
